"use client";
import { useCallback, useEffect, useState } from "react";
import cn from "@/libs/cn";
import PublicIcons from "@/components/basic/PublicIcon";
import LinkServer from "@/components/basic/Link/LinkServer";
import BlockMaxWidth from "@/modules/layout/components/BlockMaxWidth";
import useClientContext from "@/context/ClientContext";
import ButtonCatalogue from "../ButtonCatalogue";
import ButtonGlobalSearch from "../ButtonGlobalSearch";
import ButtonFavorites from "../ButtonFavorites";
import ButtonNotifications from "../ButtonNotifications";
import ButtonCart from "../ButtonCart";

import styles from "./StickyMenu.module.scss";

interface LogoProps {
  isMobileSize: boolean;
  locale: string;
}

const Logo = (props: LogoProps) => (
  <LinkServer locale={props.locale} href="/" className={styles.wrapLogo}>
    {props.isMobileSize ? (
      <PublicIcons name="mallprixLogoNameV3" className={styles.icon} objectFit="contain" fill />
    ) : (
      <PublicIcons name="mallprixLogoV3" className={styles.icon} objectFit="contain" fill />
    )}
  </LinkServer>
);

const STICKY_MENU_SELECTOR_ID = "StickyMenu";

const usePanelHighlight = () => {
  const { isMobileSize } = useClientContext();
  const [scrolled, setScrolled] = useState(false);

  const checkIsScrolled = useCallback(() => {
    const panel = document.querySelector(`#${STICKY_MENU_SELECTOR_ID}`);
    
    if (isMobileSize) {
      setScrolled(!!document.documentElement.scrollTop);
    } else {
      setScrolled(!panel?.getBoundingClientRect().top);
    }
  }, [isMobileSize])

  useEffect(() => {
    checkIsScrolled();
  }, [checkIsScrolled]);

  useEffect(() => {
    document.addEventListener("scroll", checkIsScrolled);

    return () => {
      document.removeEventListener("scroll", checkIsScrolled);
    }
  }, [checkIsScrolled]);

  return scrolled;
}

interface StickyMenuProps {}

const StickyMenu = (props: StickyMenuProps) => {
  const { locale, isMobileSize } = useClientContext();
  const scrolled = usePanelHighlight();

  return (
    <div className={cn(styles.StickyMenu, isMobileSize && styles.mobile, scrolled && styles.scrolled)} id={STICKY_MENU_SELECTOR_ID}>
      <BlockMaxWidth className={styles.wrap}>
        {!isMobileSize && <Logo locale={locale} isMobileSize={isMobileSize} />}
        <div className={styles.left}>
          <ButtonCatalogue />
          {isMobileSize && <Logo locale={locale} isMobileSize={isMobileSize} />}
        </div>
        <div className={styles.center}>{!isMobileSize && <ButtonGlobalSearch />}</div>
        <div className={styles.right}>
          {isMobileSize && <ButtonGlobalSearch mobile />}
          <ButtonFavorites className={styles.icon} classNameLabel={styles.iconLabel} />
          <ButtonNotifications className={styles.icon} classNameLabel={styles.iconLabel} />
          <ButtonCart className={styles.icon} classNameLabel={styles.iconLabel} />
        </div>
      </BlockMaxWidth>
      {/* {isMobileSize && (
        <BlockMaxWidth>
          <ButtonGlobalSearch />
        </BlockMaxWidth>
      )} */}
    </div>
  );
};

export default StickyMenu;
